<template>
  <div style="height: 100%">
    <iframe
      id="chartFrame"
      :src="url"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chartListener from '../mixins/chartListener'
import {which} from '../../package.json'
var data = null
export default {
  mixins:[chartListener],
  data() {
    return {
      url: null,
      currentData: '',
      appendUrl: "http://localhost:8081/chartFrame/?data=",
      depolyUrl: which == "3_19_Live" ? "https://itrade-demo.trustline.in/chartFrame/?data=" : "https://itrade-v2.trustline.in/chartFrame/?data=", 
      tokenList: []
    };
  },
  computed: {
    ...mapGetters("authentication", ["getUserId", "getUserSession"]),
  },
  methods: {
  // listenMwTicks(event){
  //     let tickerData = event.detail.storage
  //     let stream = document.getElementById("chartFrame")
  //     let message = {
  //       id : 'stream',
  //       message: tickerData
  //     }
  //     stream && stream.contentWindow ? stream.contentWindow?.postMessage(message, "*") : ''
  //   },

    refresh(item) {
      this.tokenList = []
      var currentChart = "";
      let getLocal = JSON.parse(localStorage.getItem("chartData"));
      let theme = localStorage.getItem('dark_theme') ? localStorage.getItem('dark_theme') : "false"
      item.data == "initial"
        ? (currentChart = getLocal["data"])
        : (currentChart = item.data);
      localStorage.setItem("chartData", JSON.stringify(getLocal));
      getLocal = JSON.parse(localStorage.getItem("chartData"));
      var token = currentChart.token;
      var exchange = currentChart.chartSymbol.includes('::INDEX') ? `${currentChart.ex}::index` :currentChart.ex;
      var symbol = currentChart.chartSymbol.split("::")[0];
      let indicators = this.$store.state.chartIndicators
      data = {
        symbol: symbol,
        interval: "1D",
        token: token,
        exchange: exchange,
        clientId: "TRUSTLINE",
        userId: this.getUserId,
        locale: "en",
        uid: "tradingview_cf3ee",
        chartsStorageUrl: which == "3_19_Live" ? "https://itrade-demo.trustline.in/rest/TSLChartAPIService" : which == "uat" ? 'https://itrade-uat.trustline.in/rest/TSLChartAPIService' : "https://itrade-v2.trustline.in/rest/TSLChartAPIService",
        chartsStorageVer: "1.1",
        autoSaveDelay: 5,
        debug: false,
        timezone: "Asia/Kolkata",
        session: this.getUserSession,
        loginType:"web",
        showPopout: true,
        isSymbolSearch: false,
        theme: theme == "true"  ? 'dark' : 'light',
        preloadProps : {
            interval: "1D",
            indicators: indicators,
          }
      };
      this.$store.commit('setTardeData' , data)
      this.currentData = data
      let destination = document.getElementById("chartFrame");
      // if(destination.src  == window.location.href)
      if (destination && destination.contentWindow && destination.src) {
        let message = {
          id : 'changeSymbol',
          message: "?data="+JSON.stringify(data)
        }
        destination.contentWindow?.postMessage(message, "*");
      } else {
         let tempUrl = window.location.protocol == 'https:' ? this.depolyUrl : this.appendUrl
        this.url = tempUrl + JSON.stringify(data)

      }
    },
  },
  mounted() {
    let getLocal = JSON.parse(localStorage.getItem("chartData"));
    if (getLocal) {
      let temp = {
        data: "initial",
        where: "chart",
      };
      this.refresh(temp);
    }
    
    // window.addEventListener('tickers-changed' , this.listenMwTicks)
  },

  created: function () {
    this.$root.$refs.TVChartContainer = this;
  },
};
</script>
import store from '@/store'
import error from '../store/Services/errorHandling'
import router from '../router'
export default {
    data(){
        return {

        }
    },
    methods:{
      listenHandler(event){
        const msgData = event.data.data
        if(event.data.id === 'ChartPopout' && router.currentRoute.path == '/chart'){
          var data = store.state.tradeChartData;
          data.showPopout = false;
          data.isSymbolSearch = false;
          data.preloadProps = {
            interval: msgData.interval,
            indicators: msgData.indicators,
          };

           let url = window.location.protocol == 'https:' ? this.depolyUrl : this.appendUrl
           let iframeUrl = url + JSON.stringify(data)
           let newWindowURL = `${window.location.protocol}//${window.location.host}/popout`
           localStorage.setItem('iframeUrl' , JSON.stringify(iframeUrl))
           window.open(
             newWindowURL,
             "_blank"
           )
         } else if(event.data.id === 'startStream'){
           let streamSymbols = msgData.streamSyms??[]    // List of symbol array need to subscribe
           this.tokenList = msgData.tokenList
           store.commit("wsConnection/setChartSubscribe" , null)
           if(streamSymbols.length > 0){
             let symbol = ''
              streamSymbols.forEach(el =>{
               symbol +=el
              })
             var tempData = {
             data: [],
             subscribe: symbol,
             where: 'tradingChart'
           }
           store.commit("wsConnection/setChartSubscribe" , symbol)
           store.dispatch("wsConnection/websocketSubscription", tempData)
           }
         }else if(event.data.id === "currentSymbol" && router.currentRoute.path == '/chart'){
             let activeSymol = event.data.data.currentSymbol
          let lastData = store.state.tradeChartData
          lastData.symbol = activeSymol.symbol
          lastData.token = activeSymol.token
          lastData.exchange = activeSymol.exchange
          store.commit('setTardeData', lastData)
         }else if(event.data.id === "apiError"){
           let msg = {
             response :{
               status : event.data.data.error
             }
           }
           error.errLog(msg)
         }else if (event.data.id === "indicatorsStoring" && (router.currentRoute.path == '/chart' || router.currentRoute.path == '/popout')) {
          const msgData = event.data.data
          store.commit("setChartIndicators", msgData);
        }
      }
    },
    mounted(){
        window.addEventListener('message', this.listenHandler)
    },
    destoryed(){
      window.removeEventListener('message', this.listenHandler)
    }
}